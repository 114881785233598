<template>
  <div>
    <v-autocomplete v-model="queue" :items="queues" label="Queue" />
    <v-text-field
      v-model="csvLength"
      append-icon="mdi-magnify"
      hide-details
      label="Csv length"
      @click:append="getCsvList()"
    />
    <v-autocomplete
      v-model="csv"
      :items="csvList"
      item-text="csvName"
      item-value="csvName"
      label="Csv"
    />
    <v-radio-group row v-model="isOneByOne">
      <v-radio label="One by one" :value="true" checked />
      <v-radio label="Same time" :value="false" />
    </v-radio-group>
    <v-text-field
      v-if="isOneByOne"
      v-model="timer"
      label="Timer (ms)"
      type="number"
    />
    <v-text-field
      v-model="ordersLength"
      append-icon="mdi-magnify"
      hide-details
      label="Orders length"
      @click:append="isOneByOne ? _getOrders() : getOrders()"
    />
    <div
      v-for="(response, i) in pickingResponses"
      :key="i"
      style="font-size: 12px"
    >
      {{ pickingResponses.length - i + ". " + response }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Test",
  data: () => ({
    queue: "",
    queues: [],
    csv: "",
    isOneByOne: true,
    timer: 0,
    csvLength: 10,
    csvList: [],
    ordersLength: 10,
    orders: [],
    pickingResponses: [],
  }),
  created() {
    this.$http.get("Queues").then((response) => {
      this.queues = response.data;
      this.queue = this.queues[0];
    });
  },
  methods: {
    getCsvList() {
      this.$http
        .post(`Shifts/Search`, { pageNumber: 1, pageSize: this.csvLength })
        .then((response) => {
          this.csvList = response.data.data.items;
        });
    },
    async getOrders() {
      this.pickingResponses = [];
      let orderRes = await this.$http.post("/Orders/Paginated", {
        pageNumber: 1,
        pageSize: this.ordersLength,
        search: this.csv,
        searchColumn: "CsvName",
      });
      let orders = orderRes.data.data.items;

      orders.forEach(async (order) => {
        let boxRes = await this.$http.post(`/Orders/${order.orderId}/Boxes`);
        let boxes = boxRes.data.data;

        boxes.forEach(async (box) => {
          let orderLineRes = await this.$http.post(
            `Orders/Boxes/${box.orderBoxId}/OrderLines`
          );

          await this.$http.get(
            `/OrderProcess/${this.queue}/${box.orderBoxId}/Scan`
          );

          let orderLines = orderLineRes.data.data;
          orderLines.forEach(async (orderLine) => {
            if (orderLine.quantity - orderLine.picked > 0) {
              let pickRes = await this.$http.post("/OrderProcess/Pick", {
                queueName: this.queue,
                orderLineId: orderLine.orderLineId,
                quantity: orderLine.quantity - orderLine.picked,
                productType: 1,
              });
              this.pickingResponses.unshift(JSON.stringify(pickRes.data));
            } else {
              this.pickingResponses.unshift(
                `Already picked. Box: ${orderLine.orderBoxId} Ean: ${orderLine.ean} Id: ${orderLine.orderLineId}`
              );
            }
          });
        });
      });
    },
    async _getOrders() {
      this.pickingResponses = [];
      let orderRes = await this.$http.post("/Orders/Paginated", {
        pageNumber: 1,
        pageSize: this.ordersLength,
        search: this.csv,
        searchColumn: "CsvName",
      });
      let orders = orderRes.data.data.items;
      for (let i = 0; i < orders.length; i++) {
        let order = orders[i];
        let boxRes = await this.$http.post(`/Orders/${order.orderId}/Boxes`);
        let boxes = boxRes.data.data;

        for (let j = 0; j < boxes.length; j++) {
          let box = boxes[j];
          await this.$http.get(
            `/OrderProcess/${this.queue}/${box.orderBoxId}/Scan`
          );
          let orderLineRes = await this.$http.post(
            `Orders/Boxes/${box.orderBoxId}/OrderLines`
          );
          let orderLines = orderLineRes.data.data;

          for (let k = 0; k < orderLines.length; k++) {
            let orderLine = orderLines[k];
            if (orderLine.quantity - orderLine.picked > 0) {
              let startTime = new Date();
              let pickRes = await this.$http.post("/OrderProcess/Pick", {
                queueName: this.queue,
                orderLineId: orderLine.orderLineId,
                quantity: orderLine.quantity - orderLine.picked,
                productType: 1,
              });
              let endTime = new Date();
              let resTime = endTime - startTime;
              this.pickingResponses.unshift(
                JSON.stringify(pickRes.data) + " - " + resTime + "ms"
              );
            } else {
              this.pickingResponses.unshift(
                `Already picked. Box: ${orderLine.orderBoxId} Ean: ${orderLine.ean} Id: ${orderLine.orderLineId}`
              );
            }
            if (this.timer > 0) {
              await new Promise((resolve) => setTimeout(resolve, this.timer));
            }
          }

          let startTime = new Date();
          let processRes = await this.$http.get(
            `/OrderProcess/${this.queue}/${box.orderBoxId}/Process`
          );
          let endTime = new Date();
          let resTime = endTime - startTime;
          this.pickingResponses.unshift(
            JSON.stringify(processRes.data) + " - " + resTime + "ms"
          );
        }
      }
    },
  },
};
</script>